<template lang="pug">
  v-dialog(:value="value" @input="updateValue" :width="width" :max-width="width")
    template(v-slot:activator="{on}")
      slot(name="activator" :on="on")
    v-card(data-test="confirmation-dialog" v-if="value" v-click-outside="onOutsideClick")
      v-card-title.headline(:class="{'error': error, 'white--text': error, 'mb-3': error}") {{ title }}
        slot(name="header")
      v-card-text {{ descr }}
        slot
      v-card-actions
        slot(name="actions")
          v-spacer
          v-btn(color="error" text @click="_failBtnClicked") {{ failText }}
          v-btn(color="success" text @click="_okBtnClicked" data-test="confirmation-dialog-ok") {{ okText }}
</template>

<script>
import vClickOutside from 'v-click-outside'

export default {
  directives: {
    clickOutside: vClickOutside.directive
  },
  props: {
    okBtnText: {
      type: String,
      default: 'Disagree'
    },
    failBtnText: {
      type: String,
      default: 'Agree'
    },
    descr: String,
    error: Boolean,
    title: String,
    value: {
      type: Boolean,
    },
    width: {
      type: String,
      default: '340px'
    },
    okText: {
      type: String,
      default: 'Agree'
    },
    failText: {
      type: String,
      default: 'Disagree'
    }
  },

  methods: {
    updateValue: function (value) {
      this.$emit('input', value)
    },

    _failBtnClicked() {
      this.$emit('failBtnClicked')
      this.updateValue(false)
    },

    _okBtnClicked() {
      this.$emit('okBtnClicked')
      this.updateValue(false)
    },

    onOutsideClick() {
      this.$emit('click:outside')
    }

  }
}
</script>

<style lang="scss" scoped>
.headline {
  word-break: break-word;
}
</style>